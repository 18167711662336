import React from "react";
import "./style.scss";
import cartoon1 from "../../assets/roadmap/cartoon1.svg";
import cartoon2 from "../../assets/roadmap/cartoon2.svg";
import cartoon3 from "../../assets/roadmap/cartoon3.png";
import cartoon4 from "../../assets/roadmap/cartoon4.svg";
import cartoon5 from "../../assets/roadmap/cartoon5.svg";

import sign1 from "../../assets/roadmap/sign1.svg";
import sign2 from "../../assets/roadmap/sign2.svg";
import sign3 from "../../assets/roadmap/sign3.svg";
import sign4 from "../../assets/roadmap/sign4.svg";
import sign5 from "../../assets/roadmap/sign5.svg";

import cloud4 from "../../assets/general/Cloud4.png";
import cloud from "../../assets/roadmap/cloud.svg";

const Roadmap = () => {
  return (
    <section className="roadmap">
      <div className="roadmap_imgsContainer">
        <img src={cloud} alt="cloud" className="roadmap_cloud" />
        <img src={cloud} className="roadmap_imgsBgCloud2" alt="cloud" />
        <img src={cloud4} className="roadmap_imgsColud" alt="cloud" />
        <img src={cartoon1} className="roadmap_cartoon" alt="cartoon" />
        <img src={sign1} className="roadmap_sign roadmap_sign1 " alt="sign" />
      </div>
      <div
        style={{ zIndex: "10" }}
        className="roadmap_imgsContainer roadmap_imgsContainerRight"
      >
        <img src={cloud} className="roadmap_imgsBgCloud2" alt="cloud" />
        <img
          src={cloud4}
          className="roadmap_imgsColud roadmap_imgsColudRight"
          alt="cloud"
        />
        <img src={sign2} className="roadmap_sign" alt="sign" />
        <img
          src={cartoon2}
          className="roadmap_cartoon"
          alt="cartoon"
          style={{ transform: "translateY(10vh)" }}
        />
      </div>
      <div className="roadmap_imgsContainer" style={{ zIndex: "20" }}>
        <img src={cloud} className="roadmap_imgsBgCloud2" alt="cloud" />
        <img src={cloud4} className="roadmap_imgsColud" alt="cloud" />
        <img src={cartoon3} className="roadmap_cartoon" alt="cartoon" />
        <img
          src={sign3}
          className="roadmap_sign roadmap_signRighat"
          alt="sign"
        />
      </div>
      <div
        style={{ zIndex: "30" }}
        className="roadmap_imgsContainer roadmap_imgsContainerRight"
      >
        <img src={cloud} className="roadmap_imgsBgCloud2" alt="cloud" />
        <img
          src={cloud4}
          className="roadmap_imgsColud roadmap_imgsColudRight"
          alt="cloud"
        />
        <img src={sign4} className="roadmap_sign" alt="sign" />
        <img
          src={cartoon4}
          className="roadmap_cartoon"
          alt="cartoon"
          style={{ transform: "translateY(10vh)" }}
        />
      </div>
      <div className="roadmap_imgsContainer" style={{ zIndex: "40" }}>
        <img src={cloud} className="roadmap_imgsBgCloud2" alt="cloud" />
        <img src={cloud4} className="roadmap_imgsColud" alt="cloud" />
        <img src={cartoon5} className="roadmap_cartoon" alt="cartoon" />
        <img
          src={sign5}
          className="roadmap_sign roadmap_signRighat"
          alt="sign"
        />
      </div>
    </section>
  );
};

export default Roadmap;
