import React, { useState } from "react";
import Modal from "../Modal";
import "./style.scss";
import coinImg from "../../../assets/market/BNB.png";
import crossIcon from "../../../assets/market/crossIcon.svg";

import heartIcon from "../../../assets/market/heartIcon.png";
import swordIcon from "../../../assets/market/swordIcon.png";
import shoeIcon from "../../../assets/market/shoeIcon.png";
import horseMagnetIcon from "../../../assets/market/horseMagnetIcon.png";

const BOX = process.env.REACT_APP_BOX_BSC;
const WEAPON = process.env.REACT_APP_WEAPON_BSC;
const WING = process.env.REACT_APP_WING_BSC;
const BSC = JSON.parse(process.env.REACT_APP_CHAIN_BSC);

const NftModal = ({ 
  isOpen, setIsOpen, collection,nft,
  userdb,
  setUserdb,
  transfer721,
  msgBox,
  msgLog,
  fnApi,
  checkWallet,   
  effectNFTs,
  setEffectNFTs,
  listNFT,
  purchaseNFT,
  removeNFT,
  setLoading,
 }) => {
  const [selectedCard, setSelectedCard] = useState(-1);
  const [wallet, setWallet] = useState('');
  const [priceList, setPriceList] = useState('');
  const [disable, setDisable] = useState(false);

  let collection_button=()=>{
    return(
      <>

              {/*!collection &&(
                <div className="swordModal_footerCoinWrapper">
                  <div className="swordModal_footerCoinContainer">
                  {nft.price+' BNB'}<span className="swordModal_footerCoinName">{coinName}</span>
                    <img src={coinImg} className="swordModal_footerCoin" alt="coin" />
                  </div>
                  <button className="swordModal_footerBtn"
                    disabled={disable}
                    onClick={async()=>{
                      setDisable(true);
                      msgLog('buyyyyyyyyyyyyy');
                      await purchaseNFT(nft);
                      setIsOpen(false);
                      setDisable(false);
                    }}
                  >Buy</button>
                </div> 
                  )*/}
              {/*!collection &&(
                <div className="swordModal_footerCoinWrapper">
                  <button className="swordModal_footerBtn"
                    disabled={disable}
                    onClick={async()=>{
                      setDisable(true);
                      msgLog('delist');
                      await removeNFT(nft);
                      setIsOpen(false);
                      setDisable(false);
                    }}
                  >Delist</button>
                </div> 
                  )*/}              
                    {!collection&&
                    <div className="swordModal_ButtonWrapper">
                      <div className="swordModal_footerCoinContainer">
                        <img src={coinImg} className="swordModal_footerCoin" alt="coin" />{nft.price+' BNB'}
                      </div>
                      <button 
                        className="swordModal_footerBtn"
                        type="button"
                        disabled={disable}
                        onClick={async()=>{
                          setDisable(true);
                          setLoading(true);      
                          setIsOpen(false);
                          msgLog('buyyyyyyyyyyyyy');
                          await purchaseNFT(nft);
                          setDisable(false);
                        }}
                      >Buy</button>
                    </div>}
                    {!collection&&nft?.seller===userdb.w_address&&
                    <div className="swordModal_ButtonWrapper">
                      <button 
                        className="swordModal_footerBtn"
                        type="button"
                        disabled={disable}
                        onClick={async()=>{
                          setDisable(true);
                          setLoading(true);      
                          setIsOpen(false);
                          msgLog('delist');
                          await removeNFT(nft);
                          setDisable(false);
                        }}
                      >Delist</button>
                    </div>}




                {collection&&(
                  <>
                    <div className="XtalBoxModal_inputContainer">
                      <input
                        type="text"
                        className="XtalBoxModal_input"
                        disabled={disable}
                        placeholder="Input address to send NFT"
                        onChange={(e) => {
                          setWallet(e.target.value);
                          setPriceList('');
                        }}
                        value={wallet}          
                      />
                      <button 
                        className="XtalBoxModal_btnFilled"
                        type="button"
                        disabled={disable}
                        onClick={async()=>{
                          setDisable(true);
                          setLoading(true);      
                          setIsOpen(false);
                          if (wallet===''){
                            msgBox('Warning! Please confirm your wallet address again. If you enter the wrong wallet address you will lose this NFT forever!');
                          } else if (/^0x[a-fA-F0-9]{40}$/.test(wallet)) {
                            let transactionHash = await transfer721(nft.token_address,nft.token_id,wallet);
                            if (transactionHash) {
                                msgBox(`Success! Request sent successfully. Please wait a few minutes before checking your NFT.`);
//                                title: "Your NFT was successfully Transfer",
//                                content: `Your NFT has been sent to the address.`,

                                setWallet('');
                                setEffectNFTs(effectNFTs+1);
                            }          
                          } else {
                            msgBox('Warning! Please confirm your wallet address again. If you enter the wrong wallet address you will lose this NFT forever!');
                          }
                          setLoading(false);      
                          setDisable(false);
                        }}
                      >Send</button>
                    </div>

                    <div className="XtalBoxModal_inputContainer">
                      <input
                        type="number"
                        className="XtalBoxModal_input"
                        disabled={disable}
                        placeholder="Set the price ( BNB )"
//                        maxLength={5}
                        onChange={(e) => {
                          if (e.target.value.length<=5){
                            setPriceList(e.target.value);
                            setWallet('');  
                          }
                        }}
                        value={priceList}          
                      />
                      <button 
                        className="XtalBoxModal_btnFilled"
                        type="button"
                        disabled={disable}
                        onClick={async()=>{
                          setDisable(true);
                          setLoading(true);      
                          setIsOpen(false);
                          if (priceList>=0.001){
                            msgLog('listttttttttttttttt');
                            await listNFT(nft,priceList);
                            msgLog('listttttttttttttttt2');
                            setPriceList('');
                          } else {
                            setLoading(false);      
                            msgBox("Price must be greater than or equal to 0.001 BNB");
                          }
                          setDisable(false);
                        }}
                      >List</button>
                    </div>
                    </>
                )}
                    {collection&&nft.sync&&
                    //collection&&userdb.weapon.findIndex((a)=>a.wa===nft.token_id)===-1&&[WEAPON,WING].includes(nft.token_address)&&
                      <div className="XtalBoxModal_inputContainer">
                      <input
                        type="text"
                        className="XtalBoxModal_input"
                        placeholder="Sync to game requires 10 dXTAL"
                        disabled={true}
                      />
                      <button 
                        className="XtalBoxModal_btnFilled"
                        type="button"
                        disabled={disable}
                        onClick={async()=>{
                          setDisable(true);
                          setLoading(true);      
                          setIsOpen(false);
                            if (!userdb.dxtal || userdb.dxtal < 10) {
                              msgBox("Not enough dXTAL! You must have 10 dXTAL to sync");
                            } else {
                              msgBox(`Warning! don't close or refresh the page before the success windows pop-ups to prevent any asset loss.`);
                              let res_fnApi=await fnApi('check_nft',{t:userdb.web_token,i: nft.token_id, a: nft.token_address, c:BSC.key});
                              if (res_fnApi){      
                                  res_fnApi=await fnApi('sync_nft',{t:userdb.web_token,i:res_fnApi.i});
                                  if (res_fnApi.user) {
                                    setUserdb(res_fnApi.user);
                                    msgBox(`Success! Request sent successfully. Please wait a few minutes before checking your status.`);
                                  } else {
                                    msgBox("Can't Sync or already Synced");
                                  }
                              } else {
                                msgBox("Can't Sync or already Synced");
                              }
                            }
                          setLoading(false);      
                          setDisable(false);
                        }}
                      >Sync</button>
                    </div>}

                    {collection&&[BOX].includes(nft.token_address)&&
                    <div className="XtalBoxModal_inputContainer">
                      <input
                        type="text"
                        className="XtalBoxModal_input"
                        placeholder="Reveal the box"
                        disabled={true}
                      />
                      <button 
                        className="XtalBoxModal_btnFilled"
                        type="button"
                        disabled={disable}
                        onClick={async()=>{
                          setDisable(true);
                          setLoading(true);      
                          setIsOpen(false);
                          let nftopenbox = nft;
//                          await checkWallet();
                          let res_fnApi=await fnApi('reveal_check',{t:userdb.web_token,i: nftopenbox.token_id, a: nftopenbox.token_address, c:BSC.key});
                          
                          if (res_fnApi?.i) {
                            let transactionHash = await transfer721(nftopenbox.token_address,nftopenbox.token_id);
//                            msgLog('tttttttttttt',transactionHash);
                            if (transactionHash) {
                              res_fnApi=await fnApi('reveal',{t:userdb.web_token,i: res_fnApi.i});
//                              msgLog(res_fnApi);
                              if (res_fnApi) {    
                                msgBox(`Your NFT was successfully Reveal. Your weapon will appear in a few minutes depending on the chain traffic. That weapon will sync into your game account momentary.`);
                                setEffectNFTs(effectNFTs+1);
                              }
                            }
                          } else {
                            alert("Already revealed")
                          }
                          setLoading(false);      
                          setDisable(false);
                        }}
                      >Reveal</button>
                    </div>}

      </>

    )
  }

  if ([BOX,WING].includes(nft.token_address)) {
    return (
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="XtalBoxModal">
          <div className="XtalBoxModal_main">
            <div className="XtalBoxModal_ImgContainer">
              <img src={nft?.image} className="XtalBoxModal_Img" alt="box" />
            </div>
            <div className="XtalBoxModal_contentContainer">
              <div className="XtalBoxModal_content">
                <div className="XtalBoxModal_number">{nft?.name} #{nft?.token_id}</div>
                <div className="XtalBoxModal_detailBox">
                  {nft?.description}
                </div>

                {/*!collection && (
                  <div className="XtalBoxModal_coinValueContainer">
                    <div className="XtalBoxModal_coinValue">
                      0.5 <span className="XtalBoxModal_coinValueName">{coinName}</span>
                      <img src={coinImg} className="XtalBoxModal_coin" alt="coin" />
                    </div>
                    <button className="XtalBoxModal_btnFilled XtalBoxModal_btnBuy">
                      Buy
                    </button>
                  </div>
                )*/}
                {/*collection && (
                  <>
                    <div className="XtalBoxModal_inputContainer">
                      <input
                        type="number"
                        className="XtalBoxModal_input"
                        placeholder="Set the price"
                      />
                      <div className="XtalBoxModal_coinContainer">
                        <div className="XtalBoxModal_coinName">{coinName}</div>
                        <img
                          src={coinImg}
                          className="XtalBoxModal_coin"
                          alt="coin"
                        />
                      </div>
                    </div>
                    <div className="XtalBoxModal_btns">
                      <button className="XtalBoxModal_btnFilled">List</button>
                      <button className="XtalBoxModal_btnOutlined">Cancel</button>
                    </div>
                  </>
                )*/}
                {collection_button()}
                
              </div>
              <img
                src={crossIcon}
                onClick={() => {setIsOpen(false);setSelectedCard(-1);}}
                className="XtalBoxModal_cross"
                alt="cross"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  } else if (nft.token_address===WEAPON){
    let ty=nft.attributes[0]-1;
    let el=Math.ceil(nft.attributes[4]/32)-1;
    let cards=nft.attributes.slice(1,5);    //1-160
//  (Grip )(Wing) ธาตุ ชนิด of the (Gem) (Blade) (Mark) by คนคราฟ
    let el_arr=['Flame ','Arcane ','Icy ','Light ','Dark '];
    let ty_arr=['Sword ','Spear ','Staff ','Mace ','Glaive '];
    let name_arr=[
      '','Cosmic ','Obsidian ','Tempest ','Earth ','Tidal ','Divine ','Nether ',
      '','Guardian ','Rune ','Fang ','Breaker ','Fury ','Bane ','Void ',
      '','Great ','Eternal ','Blessed ','Fallen ','Mighty ','Invincible ','Undying ',
      'Hero ','Titan ','Dragon ','Champion ','Victor ','Beast ','Soul ','King ',
    ];
    let c1=name_arr[(cards[0]-1)%32];
    let c2=name_arr[(cards[1]-1)%32];
    let c3=name_arr[(cards[2]-1)%32];
    let c4=name_arr[(cards[3]-1)%32];
    let e1=el_arr[el];
    let t1=ty_arr[ty];
    let m1=nft.mark?nft.mark+' ':'';
    let name=c1+c2+e1+t1+'of the '+c3+c4+m1;

    let unitDB=[
      {	//0 fire
        wp:[
          [1.9,1.9,1.3,1.3],
          [1.8,1.8,1.4,1.4],
          [1.5,1.7,1.3,1.9],
          [2  ,1.5,1.8,1.1],
          [1.2,1.6,1.9,1.7],
        ]
      },
      {	//1 nature
        wp:[
          [1.7,1.9,1.4,1.4],
          [1.6,1.6,1.6,1.6],
          [1.4,1.7,1.4,1.9],
          [2  ,1.4,1.8,1.2],
          [1.4,1.4,1.9,1.7],
        ]		
      },			
      {	//2 frost
        wp:[
          [1.7,1.9,1.3,1.5],
          [1.4,1.8,1.4,1.8],
          [1.3,1.9,1.3,1.9],
          [2  ,1.6,1.8,1  ],
          [1.3,1.5,1.9,1.7],
        ]		
      },
      {	//3 holy
        wp:[
          [1.7,1.9,1.5,1.3],
          [1.8,1.4,1.8,1.4],
          [1.6,1.7,1.2,1.9],
          [2  ,1.3,2  ,1.1],
          [1.5,1.3,1.9,1.7],
        ]		
      },			
      {	//4 shadow
        wp:[
          [1.7,1.9,1.6,1.2],
          [1.4,1.4,1.8,1.8],
          [1.3,1.7,1.5,1.9],
          [2  ,1.3,1.8,1.3],
          [1.3,1.3,1.9,1.9],
        ]		
      },
    ];
    let stats=unitDB[el].wp[ty];
    let FooterStats = [
      {
        icon: heartIcon,
      },
      {
        icon: swordIcon,
      },
      {
        icon: shoeIcon,
      },
      {
        icon: horseMagnetIcon,
      },
    ];
    
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="swordModal">
        <div className="swordModal_wrapper">
          <div className="swordModal_main">
            <div
              className="swordModal_contentContainer"
              onClick={() => setSelectedCard(-1)}
            >
              <div className="swordModal_leavesContainer"/>
              <div className="swordModal_leavesContainer">
                <img 
                  src={require('../../../assets/LogoEl/'+el+'.png')}
                  className="swordModal_leaves" alt="leaves" />
                <div className="swordModal_leavesHeading">
                {nft?.name} #{nft?.token_id} Craft {nft.craft}/5<br />
                {name}
                </div>
              </div>
              <img src={nft?.image} className="swordModal_sword" alt="sword" />
              <div className="swordModal_leavesContainerMobile">
                <img 
                  src={require('../../../assets/LogoEl/'+el+'.png')}
                  className="swordModal_leaves" alt="leaves" />
                <div className="swordModal_leavesHeading">
                  {nft?.name} #{nft?.token_id} Craft {nft.craft}/5<br />
                  {name}
                </div>
              </div>
              <div className="swordModal_text">
              {nft?.description}
              </div>
              <div className="swordModal_footerBoxes">
                {FooterStats?.map((stat, i) => (
                  <div className="swordModal_footerBox" key={i}>
                    <img
                      src={stat?.icon}
                      className="swordModal_footerBoxIcon"
                      alt="icon"
                    />
                    <span className="swordModal_footerBoxText">
                      x {stats[i]}
                    </span>
                  </div>
                ))}
              </div>              
              <div className="swordModal_footerBoxesMobile">
                {FooterStats?.map((stat, i) => (
                  <div className="swordModal_footerBox" key={i}>
                    <img
                      src={stat?.icon}
                      className="swordModal_footerBoxIcon"
                      alt="icon"
                    />
                    <span className="swordModal_footerBoxText">
                      {stats[i]}
                    </span>
                  </div>
                ))}
              </div>              
            </div>
            <div className="swordModal_cardsContainer">
              <div className="swordModal_cards">
                {selectedCard > -1 && (
                  <img
                    src={require('../../../assets/Cards/card'+cards[selectedCard]+'.png')}
                    className="swordModal_selectedCard"
                    alt="card"
                    onClick={() => setSelectedCard(-1)}
                  />
                )}

                {cards?.map((card, i) => (
                  <img
                    key={i}
                    src={require('../../../assets/Cards/card'+card+'.png')}
                    onClick={() => setSelectedCard(i)}
                    className="swordModal_card"
                    alt="card"
                  />
                ))}
              </div>
              <img
                src={crossIcon}
                className="swordModal_crossIcon"
                alt="cross"
                onClick={() => {setIsOpen(false);setSelectedCard(-1);}}
              />
            </div>
          </div>
          <div className="swordModal_footer">
              {/*!collection &&(
                <div className="swordModal_footerCoinWrapper">
                  <div className="swordModal_footerCoinContainer">
                    0.5 <span className="swordModal_footerCoinName">{coinName}</span>
                    <img src={coinImg} className="swordModal_footerCoin" alt="coin" />
                  </div>
                  <button className="swordModal_footerBtn">Buy</button>
                </div> 
              )*/}
              {collection_button()}              
          </div>
        </div>
      </div>
    </Modal>
  );

  }
};

export default NftModal;
