import React from "react";
import "./style.scss";
import rankIcon from "../../../assets/market/Icon_Rank.svg";
import RSCIcon from "../../../assets/market/Icon_RSC.svg";
import GameIcon from "../../../assets/market/Icon_Game.svg";

const Boxes = [
  {
    icon: rankIcon,
    heading: "Activity type",
    para: "A full description of the activity. Lorem ipsum dolor sit amet.",
  },
  {
    icon: RSCIcon,
    heading: "Activity type",
    para: "A full description of the activity. Lorem ipsum dolor sit amet.",
  },
  {
    icon: rankIcon,
    heading: "Activity type",
    para: "A full description of the activity. Lorem ipsum dolor sit amet.",
  },
  {
    icon: GameIcon,
    heading: "Activity type",
    para: "A full description of the activity. Lorem ipsum dolor sit amet.",
  },
  {
    icon: rankIcon,
    heading: "Activity type",
    para: "A full description of the activity. Lorem ipsum dolor sit amet.",
  },
  {
    icon: RSCIcon,
    heading: "Activity type",
    para: "A full description of the activity. Lorem ipsum dolor sit amet.",
  },
  {
    icon: rankIcon,
    heading: "Activity type",
    para: "A full description of the activity. Lorem ipsum dolor sit amet.",
  },
  {
    icon: rankIcon,
    heading: "Activity type",
    para: "A full description of the activity. Lorem ipsum dolor sit amet.",
  },
  {
    icon: rankIcon,
    heading: "Activity type",
    para: "A full description of the activity. Lorem ipsum dolor sit amet.",
  },
];

const Activity = () => {
  return (
    <div className="activity">
      <h3 className="activity_heading">Activity</h3>
      <div className="activity_main">
        {Boxes?.map((box, i) => (
          <div className="activity_box" key={i}>
            <div className="activity_boxIconContainer">
              <img src={box?.icon} className="activity_boxIcon" alt="rank" />
              <div className="activity_boxTimeContainer">
                <div className="activity_boxDateMd">06.05.2022</div>
                <div className="activity_boxTimeMd">15:30</div>
              </div>
            </div>
            <div className="activity_boxContent">
              <div className=" activity_boxText">
                <div className="activity_boxHeading">{box?.heading}</div>
                <div className="activity_boxDate">06.05.2022</div>
              </div>
              <div className=" activity_boxText">
                <div className="activity_boxPara">{box?.para}</div>
                <div className="activity_boxTime">15:30</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Activity;
