import React from "react";
import ReactModal from "react-modal";

const Modal = ({ isOpen, setIsOpen, children }) => {
  return (
    <ReactModal
      closeTimeoutMS={200}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: "100",
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          right: "unset",
          bottom: "unset",
          border: "none",
          transform: "translate(-50%, -50%)",
          backgroundColor: "transparent",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
