import React, { useState } from "react";
import Navbar from "../components/Navbar";
import "./style.scss";
import firstScreen from "../assets/general/first-screen.jpg";
import character1 from "../assets/general/Character1.png";
import character2 from "../assets/general/Character2.png";
import getStartedBtn from "../assets/general/get-started-btn.svg";
import xtal from "../assets/general/xtal.png";
import coinmarketcap from "../assets/general/coinmarketcap.png";
import blockspot from "../assets/general/blockspot.png";
import bscscan from "../assets/general/bscscan.png";
import nomics from "../assets/general/nomics.png";
import pancake from "../assets/general/pancake.png";
import whitePaperBtn from "../assets/general/white-paper-btn.svg";
import stars from "../assets/general/stars.png";
import cloud1 from "../assets/general/cloud1.png";
import cloud2 from "../assets/general/cloud2.png";
import videoFrame from "../assets/general/video-frame.png";
import sword from "../assets/fires/sword.svg";
import spear from "../assets/fires/spear.png";
import mace from "../assets/fires/mace.png";
import staff from "../assets/fires/staff.png";
import glaves from "../assets/fires/glaves.png";
import swordPaper from "../assets/fires/paper.svg";
import panel2 from "../assets/general/panel2.png";
import firePanel from "../assets/general/panel2.svg";
import cloud3 from "../assets/general/cloud3.png";
// fires images
import fire from "../assets/fires/fire.svg";
import fireHovered from "../assets/fires/fire-hovered.svg";
import earth from "../assets/fires/earth.svg";
import earthHovered from "../assets/fires/earth_hovered.svg";
import light from "../assets/fires/light.svg";
import lightHovered from "../assets/fires/light_hovered.svg";
import ice from "../assets/fires/ice.svg";
import iceHovered from "../assets/fires/ice_hovered.svg";
import shadow from "../assets/fires/shadow.svg";
import shadowHovered from "../assets/fires/shadow_hovered.svg";
import Roadmap from "../components/Roadmap";
import Reviews from "../components/Reviews";
import Team from "../components/Team";
import Partners from "../components/Partners";

const Fires = [
  {
    normal: fire,
    hovered: fireHovered,
  },
  {
    normal: earth,
    hovered: earthHovered,
  },
  {
    normal: ice,
    hovered: iceHovered,
  },
  {
    normal: light,
    hovered: lightHovered,
  },
  {
    normal: shadow,
    hovered: shadowHovered,
  },
];

const Titles = [" Fire", "Nature", "Frost", "Holy", "Shadow"];
const Details = ["Features: high damage, attack buff, destroy opponents AP, and fire defense cards. Fire card has an advantage over Nature.",
  "Features: focusing on control and create balance, buff and debuff for long game staregy. Nature card has an advantage over Frost.",
  "Features: critical attack, buff and debuff focusing on Speed, special effect based on strike first strategy. Frost card has an advantage over Fire.",
  "Features: healing and defense actions, special effects based on numbers of characters in the party. Holy card has an advantage over Shadow.",
  "Features: focusing on curse and poison debuff, has Combo cards for special effects. Shadow card has an advantage over Holy.",
];
const Pictures = [sword, spear, staff, mace, glaves];




const Home = () => {
  const [mouseEnteredIndex, setMouseEnteredIndex] = useState(0);

  return (
    <div className="home">
      <Navbar />
      <section className="sec1">
        <img
          src={firstScreen}
          className="sec1_img"
          alt="firstScreen"
          loading="lazy"
        />
        <img src={stars} alt="stars" className="sec1_stars" />
      </section>
      <section className="sec2">
        <div className="sec2_part1">
          <img src={stars} alt="stars" className="sec2_main_stars" />
          <img src={cloud1} alt="cloud" className="sec2_part1_cloud" />
          <div className="sec2_main">
            <img src={character1} alt="character" className="sec2_character1" />
            <img src={character2} alt="character" className="sec2_character2" />
            <img src={getStartedBtn} alt="btn" className="sec2_btn" onClick={() => window.open("https://talesofxtalnia.com/download", "_self")} />
            <img src={whitePaperBtn} alt="btn" className="sec2_btn" onClick={() => window.open("https://talesofxtalnia.gitbook.io/tales-of-xtalnia-white-paper/", "_blank")} />

          </div>
        </div>
        <div className="sec2_part2">
          <img src={stars} alt="stars" className="sec2_main_stars" />
          <img src={cloud2} alt="cloud" className="sec2_part2_cloud" />
        </div>
      </section>
      <section className="sec3">
        <img src={stars} alt="stars" className="sec3_stars" />
        <img src={stars} alt="stars" className="sec3_starsBottom" />
        <img src={stars} alt="stars" className="sec3_starsCenter" />
        <img src={cloud2} alt="cloud" className="sec3_topCloud" />
        <img src={cloud2} alt="cloud" className="sec3_bottomCloud" />
        <div className="sec3_main">
          <img src={panel2} className="sec3_mainPanel" alt="panel2" />
          <div className="sec3_content">
            <h3 className="sec3_heading">Xtalnia</h3>
            <div className="sec3_para sec3_para_mb">
              "XTAL - the most prestigious item in the kingdom of Xtalnia"
              <div className="sec3_para">
                XTAL Contract Address : 0xd23eae3926431C794e2a04e1622A9446D61174A4
              </div>
              <br />

              <div className="sec3_hand" onClick={() => window.open("https://coinmarketcap.com/currencies/tales-of-xtalnia/", "_blank")}>
                <div className="sec3_para">
                  Check XTAL price on CoinMarketCap
                </div>
                <img src={coinmarketcap} alt="btn" className="sec3_xtal" />
              </div>


              <div className="sec3_hand" onClick={() => window.open("https://www.coingecko.com/en/coins/xtal", "_blank")}>
                <div className="sec3_para">
                  Check XTAL price on CoinGecko
                </div>
                <img src={xtal} alt="btn" className="sec3_xtal" />
              </div>

              <div className="sec3_hand" onClick={() => window.open("https://blockspot.io/coin/xtal", "_blank")}>
                <div className="sec3_para">
                  Check XTAL price on Blockspot
                </div>
                <img src={blockspot} alt="btn" className="sec3_xtal" />
              </div>

              <div className="sec3_hand" onClick={() => window.open("https://pancakeswap.finance/swap", "_blank")}>
                <div className="sec3_para">
                  Buy XTAL on Pancake Swap
                </div>
                <img src={pancake} alt="btn" className="sec3_pancake" />
              </div>

              <div className="sec3_hand" onClick={() => window.open("https://bscscan.com/token/0xd23eae3926431c794e2a04e1622a9446d61174a4", "_blank")}>
                <div className="sec3_para">
                  Check XTAL on BSC Scan
                </div>
                <img src={bscscan} alt="btn" className="sec3_bsc" />
              </div>

              <div className="sec3_hand" onClick={() => window.open("https://nomics.com/assets/xtal-xtal", "_blank")}>
                <div className="sec3_para">
                  Check XTAL on Nomics
                </div>
                <img src={nomics} alt="btn" className="sec3_nomics" />
              </div>


            </div>
            <div className="sec3_para sec3_para_mb">
              "RSC - the reward for those who challenge the demon lord in Xtalnia"
            </div>
            <div className="sec3_para">
              RSC Contract Address : 0x754d0b5a3da2d815408e253d9f4eaa494e4732b7
            </div>
          </div>

          <img src={videoFrame} alt="video-frame" className="sec3_videoFrame" />
          <div className="sec3_part2">
            <img src={firePanel} className="sec3_firePanel" alt="firePanel" />
            <div className="sec3_firesWrapper">
              {Fires?.map((f, i) => (
                <div className="sec3_fireContainer">
                  <img
                    src={mouseEnteredIndex === i ? f?.hovered : f?.normal}
                    onMouseEnter={() => setMouseEnteredIndex(i)}
                    onMouseLeave={() => setMouseEnteredIndex(0)}
                    className="sec3_fire"
                    alt="fire"
                  />
                </div>
              ))}
            </div>
            <div className="sec3_fireContent">
              <div className="sec3_fireTextContainer">
                <h3 className="sec3_fireHeading">{Titles[mouseEnteredIndex]}</h3>
                <div className="sec3_fireText">
                  {Details[mouseEnteredIndex]}
                </div>
              </div>
              <div className="sec3_swordContainer">
                <img src={swordPaper} alt="paper" className="sec3_swordPaper" />
                <img src={Pictures[mouseEnteredIndex]} className="sec3_sword" alt="sword" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Roadmap />
      <Reviews />
      <Team />
      <Partners />
    </div>
  );
};

export default Home;
