import React, { useState } from "react";

import "./style.scss";
//import RSCIcon from "../../../assets/market/Icon_RSC.svg";
//import RankIcon from "../../../assets/market/Icon_Rank.svg";
//import GameIcon from "../../../assets/market/Icon_Game.svg";
import RSCIcon from "../../../assets/market/profile_dXTAL.png";
import RankIcon from "../../../assets/market/profile_Ranking.png";
import GameIcon from "../../../assets/market/profile_Team.png";

//import Moralis from "moralis/types";

const Profile = ({
  userdb,
  setUserdb,
  transfer20,
  XTAL,
  RSC,
  msgBox,
  fnApi,
  checkWallet,
  setLoading,
}) => {
  const Boxes = [
    {
      icon: require('../../../assets/LogoHero/'+userdb.team[0]+'.png'),
      heading: "Email",
      value: userdb.email,
    },
    {
      icon: RSCIcon,
      heading: "In game currency",
      value: "dXTAL "+userdb.dxtal,
      value2: "RSC "+userdb.rsc,
    },
    {
      icon: GameIcon,
      heading: "Name",
      value: userdb.user_id,
    },
    {
      icon: RankIcon,
      heading: "Score",
      value: userdb.score,
    },
  ];
  const [wallet, setWallet] = useState("");
  const [wallet2, setWallet2] = useState("");
  const [syncDxtal, setSyncDxtal] = useState("");
  const [syncRsc, setSyncRsc] = useState("");
  const [claimRsc, setClaimRsc] = useState("");
  const [disable, setDisable] = useState(false);

  return (
    <div className="profile">
      <h3 className="profile_heading">Game Account</h3>
      <div className="profile_main">
        <div className="profile_boxes">
          {Boxes?.map((box, index) => (
            <div className="profile_box" key={index}>
              <div className="profile_boxInner">
                <div className="profile_boxIconContainer">
                  <img
                    src={box?.icon}
                    alt="profile"
                    className={
                      index === 0
                        ? "profile_boxIcon profile_boxIcon_Profile"
                        : "profile_boxIcon"
                    }
                  />
                </div>
                <div className="profile_boxContent">
                  <div className="profile_boxHeading">{box?.heading}</div>
                  <div className="profile_boxValue">{box?.value}</div>
                  {box?.value2&&<div className="profile_boxValue">{box?.value2}</div>}
                </div>
              </div>
            </div>
          ))}
        </div>
        {(userdb.w_address===userdb.email)?
        (
        <div className="profile_detailedBox">
          <div className="profile_detailedBox_Address">
            Please enter your PUBLIC wallet to claim your RSC.
          </div>
          <div className="profile_detailedBox_Para">
            You can find this on your Metamask.
          </div>
          <form className="profile_detailedBox_form">
            <input
              type="text"
              className="profile_detailedBox_input"
              placeholder="Enter Wallet"
              onChange={(e) => {
                setWallet(e.target.value);
                setWallet2('');
              }}
              value={wallet}              
            />
            <input
              type="text"
              className="profile_detailedBox_input"
              placeholder="Confirm Wallet"
              onChange={(e) => {
                setWallet2(e.target.value);
              }}
              value={wallet2}
            />
            <button 
              className="profile_detailedBox_btn" 
              type="button"
              disabled={disable}
              onClick={async()=>{
                setDisable(true);
                setLoading(true);
                if (wallet===''){
                  msgBox('Warning! Please confirm your wallet address again. If you enter the wrong wallet you will lose this game account forever!');
                } else if (wallet2==='') {
                  msgBox('Warning! Please confirm your wallet address again. If you enter the wrong wallet you will lose this game account forever!');
                } else if (wallet!==wallet2) {
                  msgBox('Warning! Wallet address does not match. If you enter the wrong wallet you will lose this game account forever!');
                } else if (/^0x[a-fA-F0-9]{40}$/.test(wallet2)) {
                  let res_fnApi=await fnApi('wallet',{t:userdb.web_token,w:wallet2});
                  if (res_fnApi){                    
                    setUserdb(res_fnApi);
                    setWallet('');
                    setWallet2('');
                    msgBox("Wallet added successfully! This is not connecting your wallet. Don't forget to connect before using the marketplace.");
                  }
                } else {
                  msgBox('Warning! Please confirm your wallet address again. If you enter the wrong wallet you will lose this game account forever!');
                }
                setLoading(false);
                setDisable(false);
              }}              
            >
              Add Wallet
            </button>
          </form>
        </div>          
        ):(
        <div className="profile_detailedBox">
          <div className="profile_detailedBox_Address">
            Wallet Address : {userdb.w_address.slice(0,5)+'...'+userdb.w_address.slice(-4)}
          </div>
          {/*

          <div className="profile_detailedBox_Para">
            Warning : To see Collection and Activity, please make sure your added wallet and the connecting wallet is the same.
          </div>
          <div className="profile_detailedBox_Para">
            About dXTAL : dXTAL is the in game currency which will be used to craft weapons, purchase premium item, etc. in Tales of Xtalnia.
          </div>
        */}
          <div className="profile_detailedBox_Para">
            About dXTAL : dXTAL is the in game currency which will be used to craft weapons, purchase premium item, etc. in Tales of Xtalnia.
            Warning! When dXTAL is purchased, it is irreversible. When the transaction is completed, dXTAL will appear in your game ID automatically. ( YOU CAN NOT EXCHANGE dXTAL BACK TO XTAL )
          </div>

          <form className="profile_detailedBox_form">
            <label className="profile_detailedBox_label">Purchase dXTAL :</label>

            <input
              type="text"
              className="profile_detailedBox_input"
              placeholder="input XTAL amount  ( Price 1 XTAL = 10 dXTAL )"
              onChange={(e) => {
                if (/^[1-9][0-9]*$/.test(e.target.value)||e.target.value===''){
                  setSyncDxtal(e.target.value);
                  setSyncRsc('');
                  setClaimRsc('');
                }
              }}
              value={syncDxtal}
            />
            <button
              className="profile_detailedBox_btn" 
              type="button"
              disabled={disable}
              onClick={async()=>{
                setDisable(true);
                setLoading(true);
                if (parseInt(syncDxtal)>0){
                  let transactionHash = await transfer20(XTAL,syncDxtal);
                  if (transactionHash) {
                    let res_fnApi=await fnApi('sync_token',{t:userdb.web_token,h:transactionHash,n:'dxtal'});
                    if (res_fnApi){                    
                      setUserdb(res_fnApi);
                      setSyncDxtal('');
                      msgBox(`Success! Request sent successfully. Please wait a few minutes before checking your status.`);
                    }
                  }                  
                } else {
                  msgBox(`The purchase amount must be greater than 1 XTAL`);
                }
                setLoading(false);
                setDisable(false);
              }}              
            >
              Purchase
            </button>
            <label className="profile_detailedBox_label">Sync RSC :</label>
            <input
              type="text"
              className="profile_detailedBox_input"
              placeholder="input RSC amount"
              onChange={(e) => {
                if (/^[1-9][0-9]*$/.test(e.target.value)||e.target.value===''){
                  setSyncDxtal('');
                  setSyncRsc(e.target.value);
                  setClaimRsc('');
                }
              }}
              value={syncRsc}              
            />
            <button 
              className="profile_detailedBox_btn" 
              type="button"
              disabled={disable}
              onClick={async()=>{
                setDisable(true);
                setLoading(true);
                if (parseInt(syncRsc)>0){
                  let transactionHash = await transfer20(RSC,syncRsc);
                  if (transactionHash) {
                    let res_fnApi=await fnApi('sync_token',{t:userdb.web_token,h:transactionHash,n:'rsc'});
                    if (res_fnApi){                    
                      setUserdb(res_fnApi);
                      setSyncRsc('');
                      msgBox(`Success! Request sent successfully. Please wait a few minutes before checking your status.`);
                    }
                  }
                } else {
                  msgBox(`The purchase amount must be greater than 1 RSC`);
                }
                setLoading(false);
                setDisable(false);
              }}              
            >
              Sync
            </button>
            <label className="profile_detailedBox_label">Claim RSC:</label>
            <input
              type="text"
              className="profile_detailedBox_input"
              placeholder="input RSC amount"
              onChange={(e) => {
                if (/^[1-9][0-9]*$/.test(e.target.value)||e.target.value===''){
                  setSyncDxtal('');
                  setSyncRsc('');
                  setClaimRsc(e.target.value);
                }
              }}
              value={claimRsc}               
            />
            <button 
              className="profile_detailedBox_btn" 
              type="button"
              disabled={disable}
              onClick={async()=>{
                setDisable(true);
                setLoading(true);
                if (parseInt(claimRsc) > userdb.rsc) {
                  msgBox(`Your claimable RSC is less than the amount you claim`);
                } else if (parseInt(claimRsc) >= 1000) {
                  let check=await checkWallet();
                  if (check){
                    let res_fnApi=await fnApi('claim_rsc',{t:userdb.web_token,r:claimRsc});
                    if (res_fnApi){                    
                      setUserdb(res_fnApi);
                      setClaimRsc('');
                      msgBox(`Success! Request sent successfully. Please wait a few minutes before checking your status.`);
                    }
                  }
                } else {
                  msgBox(`Minimum claim amount is 1,000 RSC`);
                }
                setLoading(false);
                setDisable(false);
              }}              
            >
              Claim
            </button>
          </form>
        </div>
        )}

      </div>
    </div>
  );
};

export default Profile;
