import React, { useState, useEffect } from "react";
import "./style.scss";
import puzzle from "../../../assets/market/puzzle.png";
import Modal from "../Modal";
import { JigsawPuzzle } from 'react-jigsaw-puzzle/lib'
import 'react-jigsaw-puzzle/lib/jigsaw-puzzle.css'


const PuzzleModal = ({ isOpen, setIsOpen, effectLogin, setEffectLogin }) => {

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="puzzleModal">
        <div className="puzzleModal_heading">Complete the puzzle</div>
        <JigsawPuzzle
          imageSrc={puzzle}
          rows={2}
          columns={2}
          onSolved={()=>setTimeout(()=>{
            setIsOpen(false);
            setEffectLogin(effectLogin+1);  
          },1)}
          className="puzzleModal_puzzle"
          alt="puzzle"
        />
      </div>
    </Modal>
  );
};

export default PuzzleModal;
