import React from 'react'
import icon from "../../../assets/market/XTALiconia.png"
import "./style.scss"

const Loading = ({ isLoading }) => {
  return (
    isLoading && <div className='loading' >
      <img src={icon} className="loading_icon" alt="icon" />
    </div>
  )
}

export default Loading