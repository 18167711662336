import React from "react";
import "./style.scss";
import logo from "../../assets/nav/nav-logo.svg";
import downloadBoard from "../../assets/nav/download-board.svg";
import homeBoard from "../../assets/nav/home-board.svg";
import marketplaceBoard from "../../assets/nav/marketplace-board.svg";
import newsBoard from "../../assets/nav/news-board.svg";
import { Outlet, Link } from "react-router-dom";
import createaccountBoard from "../../assets/nav/createaccount-board.png";




const Navbar = () => {
  return (
    <nav className="nav">
      <div className="nav_main">
        <img src={logo} alt="logo" className="nav_logo" />
        <div className="nav_boards">
          {/* <a href="https://talesofxtalnia.com" target="_self">
            <img src={homeBoard} alt="homeBoard" className="nav_board" />
          </a> */}
          <Link to="/">
            <img src={homeBoard} alt="homeBoard" className="nav_board" />
          </Link>
          <Link to="/marketplace">
            <img
              src={marketplaceBoard}
              alt="marketplaceBoard"
              className="nav_board"
            />
          </Link>
          {/*           
          <a href="https://marketplace.talesofxtalnia.com" target="_blank">
            <img
              src={marketplaceBoard}
              alt="marketplaceBoard"
              className="nav_board"
            />
          </a> */}
          {/* <Link to="/marketplace">
            <img
              src={marketplaceBoard}
              alt="marketplaceBoard"
              className="nav_board"
            />
          </Link> */}
          <a href="https://talesofxtalnia.com/news/" target="_blank">
            <img src={newsBoard} alt="newsBoard" className="nav_board" />
          </a>
          {/* <a href="https://marketplace.talesofxtalnia.com" target="_blank">
            <img src={createaccountBoard} alt="marketplaceBoard" className="nav_board" />
          </a> */}
        </div>
      </div>
      <div>
        {/* <a href="https://talesofxtalnia.com/download/" target="_self"> */}
        <Link to="/download">
          <img src={downloadBoard} alt="downloadBoard" className="nav_downloadBoard" />
        </Link> {/* </a> */}
      </div>
    </nav>
  );
};

export default Navbar;

// import { Outlet, Link } from "react-router-dom";

// const Layout = () => {
//   return (
//     <>
//       <nav>
//         <ul>
//           <li>
//             <Link to="/">Home</Link>
//           </li>
//           <li>
//             <Link to="/blogs">Blogs</Link>
//           </li>
//           <li>
//             <Link to="/contact">Contact</Link>
//           </li>
//         </ul>
//       </nav>

//       <Outlet />
//     </>
//   )
// };

// export default Layout;
