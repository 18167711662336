import React from "react";
import Modal from "../Modal";
import "./style.scss";
import crossIcon from "../../../assets/market/crossIcon.svg";

const ConfirmationModal = ({ isOpen, setIsOpen, content }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="confirmationModal">
        <div className="confirmationModal_main">
          <img
            src={crossIcon}
            onClick={() => {
              setIsOpen(false);
            }}
            className="confirmationModal_cross"
            alt="cross"
          />
          <p className="confirmationModal_text">
            {content}
          </p>
          {
          <footer className="confirmationModal_footer">
{/*            
            <button className="XtalBoxModal_btnFilled">Cancel</button>
          */}
            <button onClick={()=>{setIsOpen(false)}} className="XtalBoxModal_btnFilled">OK</button>
          </footer>
          }
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
