const API = process.env.REACT_APP_API;
//const API = "https://app2.talesofxtalnia.com";
const axios = require('axios').default;

export async function apiServer(url,data){
    let response = await axios.post(API + '/v1/' + url , data);
//    console.log('resssssssssssssssssssss',response.data);
    if (response.data?.web_token||response.data?.i||response.data?.user||response.data?.em||response.data?.weapons) {
                                    //checknft                              reveal                  craft_count
        return response.data;
    } else if (response.data?.err) {
//        alert(response.data.err);
        return response.data;
    } else {
        return '';
    }
}


/*
export async function apiLogin(email){
    let response = await axios.post(API + '/v1/login', { e: email });
    if (response.data?.web_token) {
        return response.data;
    }
    if (response.data?.err) {
        alert(response.data.err);
        return '';
    }
}
export async function apiOtp(userdb,otp){
    let response = await axios.post(API + '/v1/otp', { t: userdb.web_token, p: otp });
    if (response.data?.web_token) {
        return response.data;
    }
    if (response.data?.err) {
        alert(response.data.err);
        return '';
    }
}
*/