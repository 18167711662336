/* eslint-disable no-unused-vars */
import React from "react";
import Hero0 from "./images/windows.png";
import Hero1 from "./images/mac.png";
import ios from "./images/ios.png";
import Hero2 from "./images/android.png";
import "./info.css";
// import Section2 from "./Section2";
// import { Link } from 'react-scroll';
import Navbar from "../Navbar";


const Download = () => {
    return (
        <>
            < Navbar />
            <div className="download">
                <div className="child">
                    <a>
                        <img
                            src={ios}
                            id="Hero2"
                            alt="m"
                            style={{ width: "50%", height: "auto" }}
                        />
                        <p id="Hero1t" style={{ fontSize: "26px" }} >IOS (Coming soon)</p></a>
                    <a href="https://play.google.com/store/apps/details?id=com.bnl.txt">
                        <img
                            src={Hero2}
                            id="Hero2"
                            alt="m"
                            style={{ width: "50%", height: "auto" }}
                        />
                        <p id="Hero1t" style={{ fontSize: "26px", marginLeft: "2.5vw" }} >Android </p></a>
                    <a href="https://app.talesofxtalnia.com/" target="_blank">
                        <img
                            src={Hero0}
                            id="Hero0"
                            alt="m"
                            style={{ width: "50%", height: "auto" }}
                        />
                        <p id="Hero3t" style={{ fontSize: "26px" }} >PC (Browser Game)</p></a>
                    <a href="https://app.talesofxtalnia.com/" target="_blank">
                        <img
                            src={Hero1}
                            id="Hero1"
                            alt="m"
                            style={{ width: "50%", height: "auto" }}
                        />
                        <p id="Hero2t" style={{ fontSize: "26px" }}>Mac (Browser Game)</p></a>
                </div>
            </div>

        </>
    );
};

export default Download;
