import React, { useState } from "react";
import "./style.scss";
import flag from "../../../assets/market/flag.svg";
import flagLogo from "../../../assets/market/flag-logo.png";
import MenuIcon from "../MenuIcon";

//const API = process.env.REACT_APP_API;
//const API = "https://app2.talesofxtalnia.com";
//const axios = require('axios').default;


const Flag = ({
  loggedIn,
  menuOpen,
  setMenuOpen,
  value,
  setValue,
  setLoggedIn,
  setPuzzleModal,
  userdb,
  setUserdb,
  email,
  setEmail,
  user,
  effectNFTs,
  setEffectNFTs,
  effectMarket,
  setEffectMarket,
  fnApi,
  checkWallet,
  setLoading
}) => {

  const [otp, setOtp] = useState("");
  const [disable, setDisable] = useState(false);

  return (
    <div
      className={
        loggedIn
          ? menuOpen
            ? "market_flag market_flag_loggedIn"
            : "market_flag market_flag_loggedIn market_flag_menuClose"
          : "market_flag"
      }
    >
      <img
        src={flag}
        className={
          !loggedIn
            ? "market_flag_image market_flag_image_form"
            : menuOpen
              ? "market_flag_image"
              : "market_flag_image market_flag_image_close"
        }
        alt="flag"
      />
      <div className="market_flag_content">
        <img
          src={flagLogo}
          alt="logo"
          className={
            menuOpen
              ? "market_flag_logo"
              : "market_flag_logo market_flag_logo_close"
          }
        />
        {loggedIn && <MenuIcon open={menuOpen} setOpen={setMenuOpen} />}
        {loggedIn ? (
          <>
            <div
              className={
                menuOpen
                  ? "market_flag_optionsContainer"
                  : "market_flag_optionsContainer market_flag_optionsContainer_close"
              }
            >
              <div
                onClick={async() => {
                  let check=await checkWallet(true);
                  if (check){
                    setValue(0);
                    setEffectMarket(effectMarket+1);
                  }
                }}
                style={value === 0 ? { background: "rgba(0, 0, 0, 0.35)" } : {}}
                className="market_flag_option market_flag_option_Market"
              >
                <div className="market_flag_option_icon market_flag_option_iconMarket" />
                <div className="market_flag_option_text">Marketplace</div>
              </div>
              <div
                onClick={() => setValue(1)}
                style={value === 1 ? { background: "rgba(0, 0, 0, 0.35)" } : {}}
                className="market_flag_option market_flag_option_Profile"
              >
                <div className="market_flag_option_icon market_flag_option_iconProfile" />
                <div className="market_flag_option_text">Profile</div>
              </div>
              <div
                onClick={async() =>{
                  let check=await checkWallet(true);
                  if (check){
                    setLoading(true);
                    let res_fnApi=await fnApi('refresh',{t:userdb.web_token});
                    if (res_fnApi){
                      setUserdb(res_fnApi);
                    }  
                    setValue(2);
                    setLoading(false);
                    setEffectNFTs(effectNFTs+1);
                  }
                }}
                style={value === 2 ? { background: "rgba(0, 0, 0, 0.35)" } : {}}
                className="market_flag_option market_flag_option_Collection"
              >
                <div className="market_flag_option_icon market_flag_option_iconCollection" />
                <div className="market_flag_option_text">Collection</div>
              </div>
{/*

              <div
                onClick={() => setValue(3)}
                style={value === 3 ? { background: "rgba(0, 0, 0, 0.35)" } : {}}
                className="market_flag_option market_flag_option_Activity"
              >
                <div className="market_flag_option_icon market_flag_option_iconActivity" />
                <div className="market_flag_option_text">Activity</div>
              </div>
*/}
              <div
                onClick={() =>{
                  setUserdb('');
                  setEmail('');
                  setOtp('');
                  setLoggedIn(false);
                }}
//                style={value === 3 ? { background: "rgba(0, 0, 0, 0.35)" } : {}}
                className="market_flag_option market_flag_option_Activity"
              >
                <div className="market_flag_option_icon market_flag_iconLogout" />
                <div className="market_flag_option_text">Logout</div>
              </div>

            </div>
{/*
            <div
              className={
                menuOpen
                  ? "market_flag_option market_flag_optionLogout"
                  : "market_flag_option market_flag_optionLogout market_flag_optionLogout_close"
              }
            >
              <div className="market_flag_option_icon market_flag_iconLogout" />
              <div className="market_flag_option_text"
                onClick={()=>{
                  setUserdb('');
                  setEmail('');
                  setOtp('');
                  setLoggedIn(false);
                }}              
              >Logout</div>
            </div>
*/}
          </>
        ) : (
          <div className="market_flag_form">
            <div className="market_flag_text">Sign in with your email</div>
            <input
              type="email"
              className="market_flag_input"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
                setUserdb('');
              }}
              value={email}
            />
            {userdb?.web_token && userdb?.user_type === "pending" && (
              <>
                <div
                  className="market_flag_text"
                  style={{ marginTop: "0.55vw" }}
                >
                  New email found. Please create account.
                </div>
                <input type="text" className="market_flag_input"
                  onChange={(e) => {
                    setOtp(e.target.value)
                  }}
                  value={otp} />
              </>
            )}
            {userdb?.web_token && userdb?.user_type !== "pending" && (
              <>
                <div
                  className="market_flag_text"
                  style={{ marginTop: "0.55vw" }}
                >
                  OTP sent to your email
                </div>
                <input type="text" className="market_flag_input"
                  onChange={(e) => {
                    setOtp(e.target.value)
                  }}
                  value={otp} />
              </>
            )}

            <div
              className="market_flag_btn"
              disabled={disable}

              onClick={async()=>{
                  setDisable(true);
                  setLoading(true);
                  
                  if (userdb?.web_token){
                    let res_fnApi=await fnApi('otp',{t:userdb.web_token,p:otp});
                    if (res_fnApi){                    
                      setOtp('');
                      setValue(1)
                      setUserdb(res_fnApi);
                      setLoggedIn(true);
                    }  
                  } else {
                    setPuzzleModal(true)
                  }
                  setLoading(false);
                  setDisable(false);
                }
              }
            >{userdb?.web_token?'Sign in':'Send OTP'}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Flag;
