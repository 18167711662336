import React, { useState } from "react";
import "./style.scss";
import scene from "../../assets/reviews/scene.png";
import foreground from "../../assets/reviews/foreground.svg";
import backgroundRed from "../../assets/reviews/background-red.svg";
import cloud from "../../assets/reviews/reviews-cloud.svg";

import character1 from "../../assets/reviews/character1.svg";
import character2 from "../../assets/reviews/character2.svg";
import character3 from "../../assets/reviews/character3.svg";
import character4 from "../../assets/reviews/character4.svg";
import character5 from "../../assets/reviews/character5.svg";

import character1Hovered from "../../assets/reviews/character1-hovered.svg";
import character2Hovered from "../../assets/reviews/character2-hovered.svg";
import character3Hovered from "../../assets/reviews/character3-hovered.svg";
import character4Hovered from "../../assets/reviews/character4-hovered.svg";
import character5Hovered from "../../assets/reviews/character5-hovered.svg";

import profile from "../../assets/general/profile.svg";

import paper1 from "../../assets/reviews/paper1.png";
import paper2 from "../../assets/reviews/paper2.png";
import paper3 from "../../assets/reviews/paper3.png";
import paper4 from "../../assets/reviews/paper4.png";
import paper5 from "../../assets/reviews/paper5.png";

import dots1 from "../../assets/reviews/dots1.png";
import dots2 from "../../assets/reviews/dots2.png";
import dots3 from "../../assets/reviews/dots3.png";
import dots4 from "../../assets/reviews/dots4.png";
import dots5 from "../../assets/reviews/dots5.png";

const Reviews = () => {
  const [activeCharacter, setActiveCharacter] = useState(0);

  return (
    <div className="reviews">
      <div className="reviews_main">
        <div className="reviews_charactersContainer">
          <div className="reviews_character1">
            <div className="reviews_character1_wrapper">
              <img
                src={activeCharacter === 1 ? character1Hovered : character1}
                className="reviews_character1_photo"
                alt="character"
                onMouseEnter={() => setActiveCharacter(1)}
                onMouseLeave={() => setActiveCharacter(0)}
              />
              {activeCharacter === 1 && (
                <div
                  onMouseEnter={() => setActiveCharacter(1)}
                  onMouseLeave={() => setActiveCharacter(0)}
                  className="reviews_character1_reviewWrapper"
                >
                  <div className="reviews_character1_reviewContainer">
                    <div className="reviews_character1_content">
                      {/* <img
                        src={profile}
                        className="reviews_character1_profile"
                        alt="profile"
                      /> */}
                      <div className="reviews_character1_Name">
                        Arden
                      </div>
                      <div className="reviews_character1_Text">
                        "Arden was the chief of the Emerald Leaf tribe inhabiting the Bloomhill Grove. Arden ruled the tribe peacefully with the power of nature until Gronko, the mighty centaur and leader of the Spike Hoof clan invaded Emerald Leaf village to steal Xtal. Arden and his men fought bravely and drove away the centaurs. Ever since, Arden is on his journey for more powerful Xtal to protect his village before the centaur could regrouped and assaulted the village again.”
                      </div>
                    </div>
                    <img
                      src={paper1}
                      alt="paper"
                      className="reviews_character1_paper"
                    />
                    <img
                      src={dots1}
                      alt="dots"
                      className="reviews_character1_dots"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="reviews_character2"
            style={activeCharacter === 2 ? { zIndex: "1000" } : {}}
          >
            <div className="reviews_character2_wrapper">
              <img
                src={activeCharacter === 2 ? character2Hovered : character2}
                className="reviews_character2_photo"
                alt="character"
                onMouseEnter={() => setActiveCharacter(2)}
                onMouseLeave={() => setActiveCharacter(0)}
              />
              {activeCharacter === 2 && (
                <div
                  onMouseEnter={() => setActiveCharacter(2)}
                  onMouseLeave={() => setActiveCharacter(0)}
                  className="reviews_character2_reviewWrapper"
                >
                  <div className="reviews_character2_reviewContainer">
                    <div className="reviews_character2_content">
                      {/* <img
                        src={profile}
                        className="reviews_character2_profile"
                        alt="profile"
                      /> */}
                      <div className="reviews_character2_Name">
                        Zira
                      </div>
                      <div className="reviews_character2_Text">
                        “Zira possesses the power of shadow and was one of the best thief. When the demon lord expanded his realm, her Blood moon guild joined the dark army. Zira did not want her guild to be under influence of Lozardus so she challenged the guild leader but failed. She escaped and. Since then she wandered as a rogue but still wanted to rejoin her guild and help them out of the dark lord's control. She heard of the power of Xtal and tried to collect them to enhance her power and finish the dark lord for good.”
                      </div>
                    </div>
                    <img
                      src={paper2}
                      alt="paper"
                      className="reviews_character2_paper"
                    />
                    <img
                      src={dots2}
                      alt="dots"
                      className="reviews_character2_dots"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className="reviews_character5"
            style={activeCharacter === 5 ? { zIndex: "1000" } : {}}
          >
            <div className="reviews_character5_wrapper">
              <img
                src={activeCharacter === 5 ? character5Hovered : character5}
                className="reviews_character5_photo"
                alt="character"
                onMouseEnter={() => setActiveCharacter(5)}
                onMouseLeave={() => setActiveCharacter(0)}
              />
              {activeCharacter === 5 && (
                <div
                  onMouseEnter={() => setActiveCharacter(5)}
                  onMouseLeave={() => setActiveCharacter(0)}
                  className="reviews_character5_reviewWrapper"
                >
                  <div className="reviews_character5_reviewContainer">
                    <div className="reviews_character5_content">
                      {/* <img
                        src={profile}
                        className="reviews_character5_profile"
                        alt="profile"
                      /> */}
                      <div className="reviews_character5_Name">
                        Thea
                      </div>
                      <div className="reviews_character5_Text">
                        “Thea is a daughter of Lugus the Archbishop of Lorechester stronghold. Thea learned the power of light and used it to take care of those in need. When the demon lord awoke, his power corrupted people's minds including Kenrith, the king of Lorechester. He ordered his armies to collect Xtal in nearby lands ,as a result, the war had begun. The cities around Lorechester revolted against Kenrith. Lugus went missing in the war. Thea is on a journey to find her father and to destroy Lozardus once and for all.”
                      </div>
                    </div>
                    <img
                      src={paper5}
                      alt="paper"
                      className="reviews_character5_paper"
                    />
                    <img
                      src={dots5}
                      alt="dots"
                      className="reviews_character5_dots"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="reviews_character3">
            <div className="reviews_character3_wrapper">
              <img
                src={activeCharacter === 3 ? character3Hovered : character3}
                className="reviews_character3_photo"
                alt="character"
                onMouseEnter={() => setActiveCharacter(3)}
                onMouseLeave={() => setActiveCharacter(0)}
              />
              {activeCharacter === 3 && (
                <div
                  onMouseEnter={() => setActiveCharacter(3)}
                  onMouseLeave={() => setActiveCharacter(0)}
                  className="reviews_character3_reviewWrapper"
                >
                  <div className="reviews_character3_reviewContainer">
                    <div className="reviews_character3_content">
                      {/* <img
                        src={profile}
                        className="reviews_character3_profile"
                        alt="profile"
                      /> */}
                      <div className="reviews_character3_Name">
                        Eirwen
                      </div>
                      <div className="reviews_character3_Text">
                        “Eirwen was born to a wealthy family in the town near Whispering Tundra. Eirwen possesses the power of frost and is the smartest among his age. Afraid of their son using his power in the wrong way, Eirwen’s parents sent him to the school of Azure, the great wizard guild. Eirwen is a pupil of the grand master Sagar. One day the Ice maiden, the frost spirit inhabiting the area, is acting abnormally. Sagar knows that higher power is corrutped the soul of the Ice maiden. Sagar sends Eirwen on his journey away from the Azure tower to bring the situation back to normal.”
                      </div>
                    </div>
                    <img
                      src={paper3}
                      alt="paper"
                      className="reviews_character3_paper"
                    />
                    <img
                      src={dots3}
                      alt="dots"
                      className="reviews_character3_dots"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="reviews_character4">
            <div className="reviews_character4_wrapper">
              <img
                src={activeCharacter === 4 ? character4Hovered : character4}
                className="reviews_character4_photo"
                alt="character"
                onMouseEnter={() => setActiveCharacter(4)}
                onMouseLeave={() => setActiveCharacter(0)}
              />
              {activeCharacter === 4 && (
                <div
                  onMouseEnter={() => setActiveCharacter(4)}
                  onMouseLeave={() => setActiveCharacter(0)}
                  className="reviews_character4_reviewWrapper"
                >
                  <div className="reviews_character4_reviewContainer">
                    <div className="reviews_character4_content">
                      {/* <img
                        src={profile}
                        className="reviews_character4_profile"
                        alt="profile"
                      /> */}
                      <div className="reviews_character4_Name">
                        Brand
                      </div>
                      <div className="reviews_character4_Text">
                        “Brand was separated from his family when the demon lord army invaded his hometown. After the dragon matriarch Valentra stopped the demon lord army, she found Brand on the battlefield. Valentraz raised Brand as if he was her own child. When Brand was a teenager, the dragon tyrant Daetraz assaulted the Charring Peak, Valentra’s lair. The dragon matriarch was unmatch and was held captive in Daetraz’s lair. The last word of Velentra to Brand was to defeat the demon lord who empowered Daetraz using Xtal.” Brand is on his journey away from Charring Peak in search of Xtal.”
                      </div>
                    </div>
                    <img
                      src={paper4}
                      alt="paper"
                      className="reviews_character4_paper"
                    />
                    <img
                      src={dots4}
                      alt="dots"
                      className="reviews_character4_dots"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <img src={scene} className="reviews_scene" alt="scene" />
      <img src={cloud} className="reviews_cloud" alt="cloud" />
      <img src={foreground} className="reviews_foreground" alt="foreground" />
      <img
        src={backgroundRed}
        className="reviews_backgroundRed"
        alt="redBackground"
      />
    </div>
  );
};

export default Reviews;
