import "./style.scss";
import NftModal from "../NftModal";
import React, { useState } from 'react';
import rightAngle from "../../../assets/market/angle-right.svg";
import leftAngle from "../../../assets/market/angle-left.svg";
//import ConfirmationModal from "../ConfirmationModal";

const Collection = ({
  collection,
  userdb,
  setUserdb,
  nfts,
  transfer721,
  msgBox,
  msgLog,
  fnApi,
  checkWallet,
  BOX,
  WEAPON,
  WING,

  effectNFTs,
  setEffectNFTs,

  filterType,
  setFilterType,
  filterPage,
  setFilterPage,
  maxPage,

//col
  listNFT,
//market
  purchaseNFT,
  removeNFT,  


  setLoading,
}) => {
  const [collectionModal, setCollectionModal] = useState(false);
  const [nftModal, setNftModal] = useState({});
  const page=(change)=>{
    let nextPage=filterPage+change;
    msgLog('page',nextPage,maxPage);
    nextPage=nextPage<0?0:nextPage<maxPage?nextPage:maxPage-1;
    msgLog('page',nextPage);
    setFilterPage(nextPage);
  }
  return (
    <>
   
      <NftModal
        collection={collection}
        isOpen={collectionModal}
        setIsOpen={setCollectionModal}
        nft={nftModal}

        userdb={userdb} 
        setUserdb={setUserdb} 
        transfer721={transfer721}
        msgBox={msgBox}
        msgLog={msgLog}
        fnApi={fnApi}
        checkWallet={checkWallet}

        effectNFTs={effectNFTs}
        setEffectNFTs={setEffectNFTs}   


        listNFT={listNFT}   

        purchaseNFT={purchaseNFT}
        removeNFT={removeNFT}

        setLoading={setLoading}

      />
      <div className="collection">
        {/*
        <h3 className="collection_heading">
          <div style={{flex:1}}>

          {collection?'Your XTAL Collection':'Marketplace'}
          </div>
          <div onClick={()=>setFilterType('')}>
          {'[ ALL ]'}
          </div>
          <div onClick={()=>setFilterType('box')}>
          {'[ BOX ]'}
          </div>
          <div onClick={()=>setFilterType('wing')}>
          {'[ WING ]'}
          </div>
          <div onClick={()=>setFilterType('weapon')}>
          {'[ WEAPON ]'}
          </div>
          <div onClick={()=>page(-1)}>
          {'[ < PREV ]'}
          </div>
          <div>
          {'[ PAGE '+(filterPage+1)+'/'+maxPage+' ]'}
          </div>
          <div onClick={()=>page(1)}>
          {'[ NEXT > ]'}
          </div>

        </h3>
        */}
        <div className="collection_header">
          <h3 className="collection_heading">
            {collection ? "Your XTAL Collection" : "Marketplace"}
          </h3>
          <div className="collection_filterContainer">
            <select
              className="collection_filter"
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value="">ALL</option>
              <option value="box">BOX</option>
              <option value="wing">WING</option>
              <option value="weapon">WEAPON</option>
            </select>
            <div className="collection_pageChange">
              <img src={leftAngle} alt="next" onClick={() => page(-1)} />
              <span>{filterPage + 1 + "/" + maxPage}</span>
              <img src={rightAngle} alt="prev" onClick={() => page(1)} />
            </div>
          </div>
        </div>



        <div className="collection_main">
          <div className="collection_boxes">
            {nfts?.map((nft, i) => (
              <div className="collection_box" key={i}>
                <div
                  className="collection_boxImgContainer"
                  onClick={() =>{
                    if (collection||nft.price){
                      msgLog(nft);
                      setNftModal(nft);
                      setCollectionModal(true);
                    } else {
                      msgBox('This NFT is still on process. Please check back in a few minutes.');
                    }
                  }}
                >
                  <div className="collection_boxNubmer">
                    {nft?.name+' #'+nft?.token_id}
                  </div>
                  <div style={{color:'darkred',fontSize:'0.7vw',fontWeight:'bold',height:'0.1vw'}}>
                    {nft?.token_address===WEAPON?'CRAFT COUNT '+nft.craft+'/5':''}
                  </div>
                  <img
                    src={nft?.image}
                    className="collection_boxImg"
                    alt="box"
                  />
                  <div style={{color:'darkred',fontWeight:'bold',height:'0.1vw'}}>
                    {collection?(nft.sync?'Not Sync':''):(nft.price?nft.price+' BNB':'on process...')}
                  </div>

                </div>

                {/* 
                <div className="collection_boxInputContainer">
                  <input
                    type="number"
                    className="collection_boxInput"
                    placeholder="Set the price"
                  />
                  <div className="collection_boxCoinCotainer">
                    <div className="collection_boxCoinName">Eth</div>
                    <img src={coin} className="collection_boxCoin" alt="coin" />
                  </div>
                </div>
                <div className="collection_boxBtns">
                  <button className="collection_boxBtnFilled" type="button">
                    List
                  </button>
                  <button className="collection_boxBtnOutlined" type="button">
                    Cancel
                  </button>
                </div> 
                */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Collection;

/*  
  const { authenticate, isAuthenticated, isAuthenticating, user, account, logout } = useMoralis();
  const Web3Api = useMoralisWeb3Api();
//  const [nfts, setNfts] = useState([]);
  const login = async () => {
    if (!isAuthenticated) {
      await authenticate({ signingMessage: "Please sign in to Xtalnia Marketplace" })
        .then(function (user) {
          msgLog("logged in user:", user);
          msgLog(user?.get("ethAddress"));
        })
        .catch(function (error) {
          msgLog(error);
        });
    }
  }
  const logOut = async () => {
    await logout();
    msgLog("logged out");
  }

  useEffect(() => {
    const fetchNFTs = async () => {

        async function getAllNFTs() {
            let cursor = null;
            let nfts_arr =[];
            do {
              const response = await Web3Api.account.getNFTs({
                chain: "0x61",
                address: user?.get("ethAddress"),
                cursor: cursor,
              });
              msgLog(
                `Got page ${response.page} of ${Math.ceil(
                  response.total / response.page_size
                )}, ${response.total} total`
              );
              for (const nft of response.result) {
                if (![''].includes(nft.token_address)){
                    nfts_arr.push(nft)
                }
              }
              cursor = response.cursor;
            } while (cursor != "" && cursor != null);
          
            msgLog("nfts:", nfts_arr, "total nfts:", nfts_arr.length);
          }
          getAllNFTs();

      const BSCNFTs = await Web3Api.account.getNFTs({
        chain: "0x61",
        address: user?.get("ethAddress")
      });
      msgLog("NFT", BSCNFTs)
      if (BSCNFTs?.result) {
        var nfts_array = []
        for (let nft of BSCNFTs?.result) {
          if (nft?.metadata) {
            const metadata = JSON.parse(nft?.metadata)
            msgLog(metadata)
            var image = ''
            if (metadata?.image) image = metadata?.image
            if (image.startsWith("ipfs://")) {
              image = image.replace("ipfs://", "https://ipfs.io/ipfs/")
            }
            let tokenid = nft?.token_id
            nfts_array.push({
              name: metadata?.name,
              image: image,
              tokenid: tokenid
            })
          }
        }
        setNfts(nfts_array)
      }
    }
    if (isAuthenticated) {
      fetchNFTs()
    }
  }, [isAuthenticated]);
*/