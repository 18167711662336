import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import history from "./history";
import Home from "../Home";
import Marketplace from "../Marketplace";
import Download from "../components/Download/download";
import Favicon from "react-favicon";

const Routes = () => {
  return (
    <BrowserRouter history={history}>
      <Favicon url="https://talesofxtalnia.com/static/media/xtal.518afa80dea3ce31b40c.png"></Favicon>
      <Switch>
        <Route exact path="/download" component={Download} />
        <Route exact path="/" component={Home} />
        <Route exact path="/marketplace" component={Marketplace} />
        {/* <Route path="*" render={() => <h1>Not Found</h1>}></Route> */}
        <Route path="*" render={() => <Redirect to="/"></Redirect>}></Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
