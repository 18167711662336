import React from "react";
import "./style.scss";

const MenuIcon = ({ open, setOpen }) => {
  return (
    <div
      onClick={() => setOpen(!open)}
      className={open ? "open menuIcon" : "menuIcon"}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default MenuIcon;
