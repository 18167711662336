import React from "react";
import "./style.scss";
import scene from "../../assets/partners/scene.png";
import characters from "../../assets/partners/characters.png";
import wallTop from "../../assets/partners/wall-top.png";
import wallBottom from "../../assets/partners/wall-bottom.png";
import flag from "../../assets/partners/flag.png";
import twitter from "../../assets/partners/twitter.png";
import discord from "../../assets/partners/discord.png";
import youtube from "../../assets/partners/youtube.png";
import telegram from "../../assets/partners/telegram.png";
import blueTorch from "../../assets/partners/blue-torch.png";
import redTorch from "../../assets/partners/red-torch.png";
import xt from "../../assets/partners/xt.png";
import nftworld from "../../assets/partners/nftworld.png";
import boxtradex from "../../assets/partners/boxtradex.png";
import fifteeng from "../../assets/partners/15g.png";
import coingecko from "../../assets/partners/coingecko.png";
import wealthi from "../../assets/partners/wealthi.png";
import salad from "../../assets/partners/salad.png";


const Partners = () => {
  return (
    <div className="partners">
      <img src={scene} className="partners_scene" alt="scene" />
      <img src={characters} className="partners_characters" alt="characters" />
      <img src={wallTop} className="partners_wallTop" alt="wall" />
      {/* <img src={wallBottom} className="partners_wallBottom" alt="scene" /> */}
      <div className="partners_contact">
        <img src={flag} className="partners_flag" alt="flag" />
        <div className="partners_contactContentWrapper">
          <div className="partners_contactTextContainer">
            <div className="partners_contactContentContainer">
              <div className="partners_heading">Become our partner</div>
              <div className="partners_text">
                We are seeking partnership in multiple areas including
                investment, marketing, business scaling, game play development
                to name just a few. If you interested to become our partners,
                please contact founder@talesofxtalnia.com
              </div>


            </div>
          </div>
          <div className="partners_btnWrapper" onClick={() => window.open("https://twitter.com/xtalnia", "_blank")} >
            <div className="partners_btn" >Contact Us </div>
          </div>
        </div>

      </div>

      <div className="partners_footer">
        <img src={xt} className="partners_footerxt" alt="scene" />
        <img src={boxtradex} className="partners_footerboxtradex" alt="scene" />
        <img src={wealthi} className="partners_footerwealthi" alt="scene" />
        <img src={nftworld} className="partners_footernftworld" alt="scene" />
        <img src={fifteeng} className="partners_footerfifteeng" alt="scene" />
        <img src={coingecko} className="partners_footercoingecko" alt="scene" />
        <img src={salad} className="partners_footersalad" alt="scene" />
        <img src={wallBottom} className="partners_footerWall" alt="scene" />
        <img src={redTorch} className="partners_footerRedTorch" alt="scene" />
        <img src={blueTorch} className="partners_footerBlueTorch" alt="scene" />


        <div className="partners_footerMain">
          <div className="partners_footerText">
            ©2022 BOT AND LIFE CO.,LTD. ALL RIGHTS RESERVED. All trademarks referenced herein are the properties of their respective owners.
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
          </div>
          <div className="partners_footerLinks">
            <img src={twitter} className="partners_footerLink" alt="twitter" onClick={() => window.open("https://twitter.com/xtalnia", "_blank")} />
            <img src={discord} className="partners_footerLink" alt="twitter" onClick={() => window.open("https://discord.com/invite/je3Ag3TsGV", "_blank")} />
            <img src={youtube} className="partners_footerLink" alt="twitter" onClick={() => window.open("https://www.youtube.com/channel/UCRCfeBpCft2arPmeyRi9cdg", "_blank")} />
            <img src={telegram} className="partners_footerLink" alt="twitter" onClick={() => window.open("https://t.me/s/Xtalnia_TH", "_blank")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
