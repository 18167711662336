import React from "react";
import "./style.scss";
import cloud1 from "../../assets/team/Cloud1.png";
import panel from "../../assets/team/team-panel.png";
import paper from "../../assets/team/paper.png";
import profile from "../../assets/general/kiak.png";
import profile2 from "../../assets/general/jack.png";
import profile3 from "../../assets/general/por.png";
import profile4 from "../../assets/general/bank.png";
import profile5 from "../../assets/general/game.png";
import profile6 from "../../assets/general/jo.png";
import profile7 from "../../assets/general/nice.png";
import profile8 from "../../assets/general/aye.png";
import profile9 from "../../assets/general/palm.png";
import purpleSword from "../../assets/team/purple-sword.png";
import shadowHovered from "../../assets/fires/shadow_hovered.svg";
import earthHovered from "../../assets/fires/earth_hovered.svg";
import redSword from "../../assets/team/red-sword.png";
import fireHovered from "../../assets/fires/fire-hovered.svg";
import blueSword from "../../assets/team/blue-sword.png";
import iceHovered from "../../assets/fires/ice_hovered.svg";
import greenSword from "../../assets/team/green-sword.png";
import stars from "../../assets/general/stars.png";
import cloud2 from "../../assets/team/cloud2.png";

const Cards = [
  {
    name: "Tavatchai",
    post: "Co-Founder and CEO",
    details: `"Before leading Bot and Life co.ltd., Tavatchai Engbunmeesakul founded WEALTHI which uses mobile technology to solve debt for end users. WEALTHI becomes a national champion in Thailand ICT Awards, Krungthai Bank Young Enterprise Award. WEALTHI is now serving more than 10,000 customers."`,
    profile: profile,
    type: "content",
  },
  {
    name: "Thanapak",
    post: "Co-Founder and COO",
    details: `“Thanaphak Suraveratum is a board game player and gamer. He has more than 20 years of experiences playing MTG. He was the MTG Community Cup 2018 Champion. He was an instructor and columnist for board game stores and Ragnarok Online club Magazine.​"`,
    profile: profile2,
    type: "content",
  },
  //   // {
  //   //   sword: purpleSword,
  //   //   fire: shadowHovered,
  //   //   type: "sword",
  //   // },
  {
    name: "Charn",
    post: "Co-Founder and CTO",
    details: `“Charn Chanvutisil has more than 10 years of experience writing computer software in both frontend and backend. He is the main architecture and developer of WEALTHI which he combines machine learning with mobile technology to solve debt problems of the end users.​​"`,
    profile: profile3,
    type: "content",
  },
  {
    sword: redSword,
    fire: fireHovered,
    type: "sword",
    style: { top: "5%", height: "90%" },
  },
  {
    name: "Yanapat",
    post: "Marketing Lead",
    details: `“Yanapat Pijarana is trained in a computer business program, KBU. He is an avid MOBA player and is ranking in top 100 of the ROV Northern Thailand region". ​`,
    profile: profile4,
    type: "content",
  },
  {
    name: "Chanakarn",
    post: "Community Manager",
    details: `“Chanarkarn Buaklee is a second class honor from KMITL university. She enjoys her part time involving with the gaming community.​"`,
    profile: profile5,
    type: "content",
  },
  {
    name: "Arnont",
    post: "Game Artist",
    details: `“Arnont Songnok received his Bachelor of Science degree in Interactive Design and Game Development from ANT DPU which is a partner of SEA Limited. He has more than 20 years of experience as an avid MMORPG player.​"`,
    profile: profile6,
    type: "content",
  },
  {
    name: "Jirawat",
    post: "Game Developer",
    details: `“In his early 20s, Jirawat Yodthum already developed several games and was nominated to compete in Thailand National Software Contest 2021. He is also a gamer and participated in many game competitions."`,
    profile: profile7,
    type: "content",
  },
  {
    sword: purpleSword,
    fire: shadowHovered,
    type: "sword",
  },
  // {
  //   sword: blueSword,
  //   fire: iceHovered,
  //   type: "sword",
  //   style: { top: "5%", height: "90%" },
  // },
  {
    sword: greenSword,
    fire: earthHovered,
    type: "sword",
    style: { left: "15%", top: "18%", height: "75%" },
  },
  {
    name: "Sirada",
    post: "Game Artist",
    details: `“Sirada Kanchitanurak is competent in both 2D and 3D designs. She graduated from Rangsit University.​"`,
    profile: profile8,
    type: "content",
  },
  {
    name: "Supanid",
    post: "Content Creator",
    details: `“Supanid Loykaew is a second class honor from KMITL university. She has an experience working as a creator in a game project."`,
    profile: profile9,
    type: "content",
  },
];

const Team = () => {
  return (
    <div className="team">
      <img src={cloud1} alt="cloud" className="team_cloud1" />
      <img src={cloud2} alt="cloud" className="team_cloud2" />
      <img src={cloud2} alt="cloud" className="team_cloud3" />


      <img src={stars} alt="cloud" className="team_stars1" />
      <img src={stars} alt="cloud" className="team_stars2" />
      <img src={stars} alt="cloud" className="team_stars3" />


      <div className="team_main">
        <img src={panel} alt="panel" className="team_panel" />
        <div className="team_heading">Our team</div>
        <div className="team_cardsContainer">
          {Cards?.map((card) => (
            <div className="team_card">
              <img src={paper} alt="paper" className="team_cardPaper" />
              {card?.type === "content" ? (
                <>
                  <div className="team_cardProfileContainer">
                    <img
                      src={card?.profile}
                      alt="paper"
                      className="team_cardProfile"
                    />
                    <div className="team_cardName">{card?.name}</div>
                    <div className="team_cardPost">{card?.post}</div>
                  </div>
                  <div className="team_cardDetails">{card?.details}</div>
                </>
              ) : (
                <>
                  <img
                    src={card?.sword}
                    alt="sword"
                    className="team_cardSword"
                    style={card?.style}
                  />
                  <img src={card?.fire} className="team_cardFire" alt="fire" />
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
